import pugFile from "./generatedReport.pug";

export default {
  bindings: {
    user: "<",
    reportId: "=",
    reportType: "=",
  },
  controller: "GeneratedReportController",
  template: pugFile(),
};
