/* eslint-env browser */

import angular from "angular";

/* @ngInject */
/**
 * Validates the comment field length to nox exceed a max height
 * @returns {object} directive configuration
 */
export default function validatePhysicianCommentLengthDirective() {
  return {
    require: "ngModel",
    link: (scope, element, attributes, controller) => {
      controller.$validators.validatePhysicianCommentLength = function validatePhysicianCommentLength(
        modelValue,
        viewValue
      ) {
        // angular.element is used to grab the element's height AFTER the DOM has updated the new value
        return angular.element(() => {
          // Grab the "Printable" Physician comments element from the DOM
          const reportPhysicianCommentsElement = document.getElementById("physicianComments");
          const elementHeight = reportPhysicianCommentsElement
            ? Math.floor(
                Number(window.getComputedStyle(reportPhysicianCommentsElement).height.replace("px", ""))
              )
            : 0;

          // Check if the element is too tall for the page
          const isValid = elementHeight <= scope.$ctrl.maxPhysicianCommentsHeight;
          controller.$setValidity("validatePhysicianCommentLength", isValid);
          scope.$apply();
          return isValid;
        });
      };
    },
  };
}
