import {DateTime} from "luxon";

/* @ngInject */
export default class TriageController {
  constructor($scope, $window, $mdDialog, $injector, Authentication) {
    this._$scope = $scope;
    this._$rootScope = $injector.get("$rootScope");
    this._$window = $window;
    this._$mdDialog = $mdDialog;
    this._ScrollService = $injector.get("ScrollService");
    this._Search = $injector.get("SearchService");
    this._InboxItemService = $injector.get("InboxItemService");
    this._InboxItemSearchParamsService = $injector.get("InboxItemSearchParamsService");
    this._Authentication = Authentication;
    this._Lock = $injector.get("Lock");
    this._Session = $injector.get("Session");
    this._WorkflowsService = $injector.get("WorkflowsService");
    this._$state = $injector.get("$state");
    this.features = $injector.get("Config").features;

    this.triageEnabled = false;

    this._Search.autoFillValues = ["device:", "study:", "number:", "type:", "before:", "after:", "facility:"];

    this._Search.searchableColumnFields = ["$enrollment.tzSerial$", "type", "$enrollment.study.id$"];
    this._Search.customStandardSearch = null;
    this._Search.typeGroups = {
      event: [
        "tachycardia",
        "bradycardia",
        "unreadable ecg data",
        "normal sinus rhythm",
        "cardiac pause",
        "atrial fibrillation",
        "baseline",
        "ecg data request",
        "patient activated event",
      ],
    };

    this._Search.searchByCriteria = {
      is: {criteria: "category", columnName: "type", throwIfNegated: false},
      device: {criteria: "key", columnName: "$enrollment.tzSerial$", throwIfNegated: false, isExact: false},
      serial: {criteria: "key", columnName: "$enrollment.tzSerial$", throwIfNegated: false, isExact: false},
      number: {criteria: "key", columnName: "itemNumber", throwIfNegated: false, isExact: false},
      type: {criteria: "key", columnName: "type", throwIfNegated: false, isExact: false},
      study: {criteria: "key", columnName: "$enrollment.study.id$", throwIfNegated: false, isExact: false},
      facility: {
        criteria: "key",
        columnName: "$enrollment.study.facility.name$",
        throwIfNegated: false,
        isExact: false,
      },
      before: {criteria: "timestamp", period: "before", columnName: "timestamp", throwIfNegated: true},
      after: {criteria: "timestamp", period: "after", columnName: "timestamp", throwIfNegated: true},
    };

    this.myItemsSearchParams = this._InboxItemSearchParamsService.instantiate("myItems", true);
    this.itemsNotAssignedToMeSearchParams = this._InboxItemSearchParamsService.instantiate(
      "itemsNotAssignedToMe",
      true
    );

    if (!this.features.itemAssignments) {
      delete this.myItemsSearchParams.assignedToMe;
      delete this.itemsNotAssignedToMeSearchParams.assignedToMe;
    }

    this.nextAvailableItem = "";
    this.triageEnabled =
      this._WorkflowsService.workflowSettings[this._Authentication.getFacilityId()]?.triageEnabled;
    if (!this.triageEnabled) {
      this._$state.go(this._Authentication.getInitialState());
      return;
    }

    this.prefilledSearch = "";
    const searchFromLocalStorage = this._$window.localStorage.getItem("search");
    if (searchFromLocalStorage) {
      const currentTime = new Date().getTime();
      const search = JSON.parse(searchFromLocalStorage);

      if (currentTime < search.timeExpired) {
        this.prefilledSearch = search.text;
      }

      this._$window.localStorage.removeItem("search");
    }

    this.$onInit = this._init;
  }

  /// Public Functions ///
  get selectedItem() {
    return this._InboxItemService.selectedItem;
  }

  async displayNextAvailableItem() {
    this.loadingNextAvailable = true;

    try {
      await this._InboxItemService.deselectItem();

      // get the item to display on the page
      const assignedToMeParams = this._InboxItemSearchParamsService.instantiate("nextAvailableMyItems", true);
      assignedToMeParams.order = this.myItemsSearchParams.order;
      if (!this.features.itemAssignments) {
        delete assignedToMeParams.assignedToMe;
      }
      let {item, error} = await this._getNextAvailableAndCatch(assignedToMeParams);

      // if item assignments are not enabled, don't attempt to get next item a second time
      if (!item && this.features.itemAssignments) {
        const notAssignedToMeParams = this._InboxItemSearchParamsService.instantiate(
          "nextAvailableNotAssignedToMe",
          true
        );
        notAssignedToMeParams.order = this.itemsNotAssignedToMeSearchParams.order;
        ({item, error} = await this._getNextAvailableAndCatch(notAssignedToMeParams));
      }
      if (error) {
        throw error;
      }

      // pretend we clicked the item
      // this will lock it with our session id and fetch the needed ecg information
      this._ScrollService.scrollToElement("reviewNextAvailableButton");

      // Valid timestamps are 2010 or later
      item.validStudyStartDate = DateTime.fromISO(item.studyStartDate).year >= 2010;

      if (item.validStudyStartDate) {
        item.displayedStudyStartDate = DateTime.fromJSDate(new Date(item.studyStartDate)).toFormat(
          "yyyy-MM-dd HH:mm"
        );
      } else {
        item.displayedStudyStartDate = "Unknown Timestamp";
      }

      item.isTriageItem = true;
      item.isNextAvailable = true;
      this.nextAvailableItem = item;
      Object.assign(item, await this._InboxItemService.selectItem(item, false));

      this._$scope.$apply();
      setTimeout(() => this._ScrollService.scrollToElement(item.id), 100);

      this.loadingNextAvailable = false;
    } catch (err) {
      this.loadingNextAvailable = false;
      this._$scope.$apply();

      let popupTitle = "Failed to find item";
      let errorMessage = `Unable to find an inbox item available for review. Status Code: ${err.status}`;

      if (err.status === -1) {
        popupTitle = "Network Error";
        errorMessage = "There was a network error, please try again.";
      } else if (err.status === 404) {
        errorMessage = "Unable to find an inbox item available for review.";
      } else if (err.status === 403) {
        popupTitle = "Viewing maximum allowed items";
        errorMessage =
          "You have too many items open. Please close other open items in order to view this item. " +
          "If you think this is a mistake, please log out and log back in.";
        // If the error was more than an item simply not being found or exceeding locks, log it
      } else if (err.message.startsWith("Search text invalid: ")) {
        popupTitle = "Search text invalid";
        errorMessage = err.message.substr(21);
      } else {
        console.error(err);

        // If the error has a message, use that message
        if (err?.data?.detail?.message) {
          errorMessage = err.data.detail.message.replace(/\n/g, "<br />");
        }
      }

      this._$mdDialog.show(
        this._$mdDialog
          .alert()
          .title(popupTitle)
          .htmlContent(
            `<p class="warningMessage"><i class="material-icons dialogErrorIcon"> error </i> ` +
              `${errorMessage}</p>`
          )
          .ok("Ok")
      );
    }
  }

  /// Private Functions ///

  async _init() {
    await this._InboxItemService.deselectItem();
    await this._unlockAllItemsOnRefresh();
  }

  _unlockAllItemsOnRefresh() {
    if (this._Session.pageWasRefreshed()) {
      return this._Lock.unlockAllItemsForSession().then(() => this._Session.generateNewSessionId());
    }
    return Promise.resolve();
  }

  _getNextAvailableAndCatch(params) {
    return this._InboxItemService
      .getNextInboxItemForReview(params, true)
      .then((item) => ({item}))
      .catch((error) => ({error}));
  }
}
