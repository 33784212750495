import pugFile from "./activeStudies.pug";

/* @ngInject */
export default function routes($stateProvider, $urlRouterProvider) {
  const oldActiveStudiesUrl = "/active-studies";
  const oldCompletedStudiesUrl = "/completed-studies";
  const url = "/studies";

  $stateProvider.state({
    name: "studies",
    parent: "App",
    url,
    template: pugFile(),
    controller: "ActiveStudiesController as activeStudies",
    onEnter: [
      "Authentication",
      "Session",
      function authenticate(Authentication, Session) {
        Session.trackCurrentPage("/studies");
        return Authentication.authenticate();
      },
    ],
    data: {
      searchable: true,
      roles: ["clinicalStaff", "tzAdmin", "tech", "facilityAdmin", "physician"],
    },
  });

  $urlRouterProvider.when(oldActiveStudiesUrl, url);
  $urlRouterProvider.when(oldCompletedStudiesUrl, url);
}
