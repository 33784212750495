import angular from "angular";

import sharedModule from "../../shared/index.js";
import generatedReportComponent from "./generatedReport.component.js";
import GeneratedReportController from "./generatedReport.controller.js";
import routes from "./generatedReport.routes.js";
import defaultReportConfig from "./reportConfig.constant.js";
import validatePhysicianCommentLengthDirective from "./validatePhysicianCommentLength.directive.js";

export default angular
  .module("app.pages.generatedReports", [sharedModule])
  .controller("GeneratedReportController", GeneratedReportController)
  // matches <generated-report>
  .component("generatedReport", generatedReportComponent)
  // matches <validate-physician-comment-length>
  .directive("validatePhysicianCommentLength", [validatePhysicianCommentLengthDirective])
  .config(routes)
  .constant("DEFAULT_REPORT_CONFIG", defaultReportConfig).name;
