import angular from "angular";

import sharedModule from "../../shared/index.js";
import ReportsController from "./reports.controller.js";
import routes from "./reports.routes.js";

export default angular
  .module("app.pages.reports", [sharedModule])
  .controller("ReportsController", ReportsController)
  .config(routes).name;
