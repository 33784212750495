import angular from "angular";
import uirouter from "@uirouter/angularjs";

import loginComponent from "./login.component.js";
import LoginController from "./login.controller.js";
import routes from "./login.routes.js";

export default angular
  .module("app.pages.login", [uirouter])
  .config(routes)
  .controller("LoginController", LoginController)
  // matches <login>
  .component("login", loginComponent).name;
