import pugFile from "./reports.pug";

/* @ngInject */
export default function routes($stateProvider, $urlRouterProvider) {
  const url = "/reports";

  $stateProvider.state({
    name: "reports",
    parent: "App",
    url,
    template: pugFile(),
    controller: "ReportsController as reports",
    onEnter: [
      "Authentication",
      "Session",
      function authenticate(Authentication, Session) {
        Session.trackCurrentPage("/reports");
        return Authentication.authenticate();
      },
    ],
    data: {
      searchable: true,
      roles: ["clinicalStaff", "tzAdmin", "tech", "facilityAdmin", "physician"],
    },
  });
}
