/* @ngInject */
export default function routes($stateProvider, $urlRouterProvider) {
  const bandaidUrl = "/reports/:duplicate/reports/:type/:reportId";
  const generatedReportPageUrl = "/reports/:type/:reportId";

  $stateProvider
    .state({
      name: "Report Wrapper",
      abstract: true,
      controller: "AppController as app",
      resolve: {
        user: [
          "Authentication",
          "WorkflowsService",
          function getUser(Authentication, WorkflowsService) {
            let user;

            try {
              user = Authentication.getJwtPayload().user;
            } catch (error) {
              Authentication.removeJwt();

              if (error.name.includes("ExpirationError")) {
                Authentication.sendToLogin(null, error.message);
              } else {
                const params = $urlRouterProvider.router.locationConfig._baseHref.split("/");
                const type = params[params.length - 2];
                const reportId = params[params.length - 1];
                Authentication.sendToLogin(null, null, {state: "Generated Report", type, reportId});
              }
            }

            return WorkflowsService.getWorkflowSettings().then(() => {
              return user;
            });
          },
        ],
      },
    })
    .state({
      name: "Generated Report",
      parent: "Report Wrapper",
      url: generatedReportPageUrl,
      component: "generatedReport",
      onEnter: [
        "Authentication",
        "Session",
        function authenticate(Authentication, Session) {
          Session.trackCurrentPage();
          return Authentication.authenticate();
        },
      ],
      data: {
        searchable: true,
        roles: ["clinicalStaff", "tzAdmin", "tech", "facilityAdmin", "physician"],
      },
    });

  $urlRouterProvider.when(bandaidUrl, generatedReportPageUrl);
}
