import angular from "angular";

import sharedModule from "../../shared/index.js";
import InboxController from "../inbox/inbox.controller.js";
import completedComponent from "./completed.component.js";
import routes from "./completed.routes.js";

export default angular
  .module("app.pages.completed", [sharedModule])
  .controller("InboxController", InboxController)
  // matches <completed>
  .component("completed", completedComponent)
  .config(routes).name;
