/* @ngInject */
export default function routes($stateProvider) {
  $stateProvider.state({
    name: "Login",
    url: "/login",
    component: "login",
    params: {
      error: null,
      message: null,
      goto: null,
    },
    data: {
      searchable: false,
    },
  });
}
