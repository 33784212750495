import angular from "angular";

import sharedModule from "../../shared/index.js";
import ActiveStudiesController from "./activeStudies.controller.js";
import routes from "./activeStudies.routes.js";

export default angular
  .module("app.pages.activeStudies", [sharedModule])
  .controller("ActiveStudiesController", ActiveStudiesController)
  .config(routes).name;
