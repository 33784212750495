import bitRhythmLogo from "../../../img/br-logo-inbox.svg";
import tzBanner from "../../../img/tz-banner.png";
import tzLogo from "../../../img/tz-logo-inverted.svg";

/* @ngInject */
export default class LoginController {
  constructor($scope, $rootScope, $location, $injector, $state, $window, $mdToast, $mdDialog) {
    this._$scope = $scope;
    this._$rootScope = $rootScope;
    this._$location = $location;
    this._$state = $state;
    this._$window = $window;
    this._$mdToast = $mdToast;
    this._$mdDialog = $mdDialog;
    this._Authentication = $injector.get("Authentication");
    this._Config = $injector.get("Config");
    this._Auth = $injector.get("Auth");
    this._Session = $injector.get("Session");
    this._Lock = $injector.get("Lock");

    this.bitRhythmLogo = bitRhythmLogo;
    this.tzLogo = tzLogo;
    this.tzBanner = tzBanner;

    // Pull messages and redirects from query params if set
    this.redirect = decodeURIComponent($location.search().redirect);
    if (this.redirect === "undefined") {
      this.redirect = undefined;
    }
    this.redirectMessage = decodeURIComponent($location.search().message);
    this.redirectError = this._$location.search().error;

    this.loginInProgress = false;
    this.username = undefined;
    this.password = undefined;

    this.unauthorizedInboxAccessMessage =
      "It looks like you don't have access to the BitRhythm Inbox service. If you feel that you have reached this message by mistake, please contact support@bitrhythm.io.";
    this.userUnauthorizedMessage = "This account is not authorized for Inbox use.";

    // Listen for JWT changed events so we can auto-login
    const jwtChangeHandler = this._handleJwtChangeEvent.bind(this);
    this._$window.addEventListener("storage", jwtChangeHandler);
    this._$scope.$on("$destroy", () => {
      this._$window.removeEventListener("storage", jwtChangeHandler);
    });

    const deregister = this._$rootScope.$on("handle-login", (emittedEvent, jwt, email) => {
      try {
        this._handleLoginResponse(jwt, email);
      } catch (err) {
        this._handleLoginResponseError(err);
      }
    });
    this._$scope.$on("$destroy", deregister);

    this._init();
  }

  get _message() {
    return this._messages[this._messageParam];
  }

  get _messageParam() {
    let messageParam = this.redirectMessage;

    if (!messageParam) {
      messageParam = this._$state.params.message;
    }

    return messageParam;
  }

  get _messages() {
    const passwordReset = "Password reset successful. Please sign in.";
    const accountActivated = "Account activation successful. Please sign in.";
    const autoLoggedOut = "You were automatically logged out.";
    const loggedOutAnotherTab = "You were logged out from another tab.";
    const unauthorized = "Your account is not authorized for this application.";
    const unauthorizedInboxAccess =
      "It looks like you don't have access to the BitRhythm Inbox service. If you feel that you have reached this message by mistake, please contact support@bitrhythm.io.";
    const accountReset = "Password and security questions reset successful. Please sign in.";
    return {
      passwordReset,
      accountActivated,
      autoLoggedOut,
      loggedOutAnotherTab,
      unauthorized,
      unauthorizedInboxAccess,
      accountReset,
    };
  }

  async clickedSignIn() {
    this.loginInProgress = true;
    this._Session.clear();
    this._Session.generateNewSessionId();

    try {
      const response = await this._Auth.login(this.username, this.password);
      this._handleLoginResponse(response.headers("access-token"), response.data?.email);
    } catch (err) {
      this._handleLoginResponseError(err);
    }

    this.loginInProgress = false;
  }

  clickedForgotCredentials() {
    this._$window.location.href = `${this._Config.adminUrl}/requestReset`;
  }

  isAuthorized(allowedRoles) {
    return this._Authentication.isInAnyRole(allowedRoles);
  }

  isSupportedBrowser() {
    const userAgentString = this._$window.navigator.userAgent;

    const operaAgent = userAgentString.indexOf("OP") > -1;
    const chromeAgent = userAgentString.indexOf("Chrome") > -1 && !operaAgent;

    if (!chromeAgent) {
      this._displayUnsupportedBrowserWarning();
    }
  }

  _displayUnsupportedBrowserWarning() {
    return this._$mdDialog.show(
      this._$mdDialog
        .alert()
        .htmlContent(
          `<h2 class="md-title"><i class="material-icons dialogErrorIcon"> error </i> Unsupported Browser</h2>` +
            `<p class="warningMessage">BitRhythm has detected that you are using an unsupported browser.</p>` +
            `<p class="warningMessage">Consider switching to Google Chrome for a smoother BitRhythm experience.</p>`
        )
        .ok("Ok")
    );
  }

  // Abstracted out so that we can use this function for storage events too
  _handleValidJwt(jwt) {
    const jwtPayload = this._Authentication.getJwtPayload(jwt);
    this._Authentication.validateJwtPayload(jwtPayload);

    this._Lock.enableLocking();
    this._Lock.unlockAllItemsForUser().catch((err) => console.error(err));

    let initialState = this._Authentication.getInitialState();
    let routeParams = {};
    const {goto} = this._$state.params;
    if (this.redirect) {
      this._$location.path(this.redirect);
    } else {
      if (goto) {
        if (typeof goto === "string") {
          initialState = goto;
        } else if (typeof goto === "object") {
          initialState = goto.state;
          routeParams = {...goto};
          delete routeParams.state;
        }
      }
      this._$state.go(initialState, routeParams);
    }
  }

  _handleLoginResponse(jwt, email) {
    // If a JWT was returned on the response, login and go to the correct page
    if (jwt !== "undefined") {
      this._Authentication.loginStoreJwt(jwt);
      this._handleValidJwt(jwt);
    }
    // Otherwise, 2FA needs to be done, so open the React dialog
    else {
      this.email = email;
      this.twoFactorAuth = {
        open: true,
        handleLogin: (jwtResponse) => {
          this._$rootScope.$emit("handle-login", jwtResponse, email);
        },
      };

      this.loginInProgress = false;
      this._$scope.$apply();
    }
  }

  _handleLoginResponseError(error) {
    this.loginInProgress = false;
    if (error.message === "unauthorizedInboxAccess") {
      this.error = this.unauthorizedInboxAccessMessage;
    } else if (error.message === "Unauthorized") {
      this.error = this.userUnauthorizedMessage;
    } else if (error.data === null) {
      this.error = `Connection with the server (${this._Config.apiUrl}) could not be established. Please contact your IT department or BitRhythm Support.`;
    } else if (error.data) {
      this.error = error.data.title;
    } else {
      this.error = error.message;
    }

    this._showToastBanner(this.error, false);
  }

  _init() {
    this._$window.history.pushState({}, null, "/login");

    this.isSupportedBrowser();

    if (this._message) {
      this._showToastBanner(this._message, false);
    }

    if (this.error) {
      this._showToastBanner(this.error, false);
    }
  }

  _showToastBanner(message, duration = false) {
    this._$mdToast.show(
      this._$mdToast
        .simple()
        .hideDelay(duration)
        .position("top left right")
        .toastClass("md-toast-banner")
        .textContent(message)
        .action("Dismiss")
        .highlightAction(true)
        .highlightClass("md-primary")
    );
  }

  _handleJwtChangeEvent({key, newValue}) {
    if (key === "jwt" && newValue) {
      const token = this._Authentication.parseLocalStorage(newValue);
      if (token && token !== "undefined") {
        this._handleValidJwt(newValue);
      }
    }
  }
}
