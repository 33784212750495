const defaultReportConfig = {
  "Single Episode": {
    elements: ["summary", "technicianFindings", "physicianComments", "strips"],
  },

  "Daily Trend": {
    elements: [
      "summary",
      "technicianFindings",
      "physicianComments",
      "donutCharts",
      "hrTrend",
      "pvcBurden",
      "arrhythmiaTimeline",
      "heartRateSummary",
      "eventIndex",
      "strips",
    ],
  },

  Summary: {
    elements: [
      "summary",
      "technicianFindings",
      "physicianComments",
      "donutCharts",
      "hrTrend",
      "pvcBurden",
      "arrhythmiaTimeline",
      "heartRateSummary",
      "eventIndex",
      "strips",
    ],
  },

  donutCharts: {
    height: null,
    flexHeight: "nogrow",
    arrhythmiaEpisode: {
      display: true,
      order: 1,
    },
    arrhythmiaBurden: {
      display: true,
      order: 2,
    },
    ventricularEctopy: {
      display: true,
      order: 3,
    },
    atrialFibrillationStats: {
      display: true,
      order: 4,
    },
  },

  arrhythmiaTimelineHeader: {
    height: 28,
    flexHeight: "nogrow",
  },

  arrhythmiaTimelineSection: {
    height: 290,
    flexHeight: "nogrow",
  },

  arrhythmiaTimelineFooter: {
    height: null,
    flexHeight: "nogrow",
  },

  hrTrendHeader: {
    height: 28,
    flexHeight: "nogrow",
  },

  hrTrendSection: {
    height: 250,
    flexHeight: "nogrow",
  },

  hrTrendFooter: {
    height: null,
    flexHeight: "nogrow",
  },

  pvcBurdenHeader: {
    height: 28,
    flexHeight: "nogrow",
  },

  pvcBurdenSection: {
    height: 250,
    flexHeight: "nogrow",
  },

  pvcBurdenFooter: {
    height: null,
    flexHeight: "nogrow",
  },

  eventIndexHeader: {
    height: null,
    flexHeight: "nogrow",
  },

  eventIndexItem: {
    height: null,
    flexHeight: "nogrow",
    items: [
      {componentId: "stripOrder", name: "Order", width: "5"},
      {componentId: "stripTimestamp", name: "Timestamp", width: "20"},
      {componentId: "stripUserClassification", name: "Classification", width: "20"},
      {componentId: "triggerType", name: "Trigger", width: "10"},
      {componentId: "stripComments", name: "Findings", width: "30"},
      {componentId: "meanHR", name: "HR", width: "10"},
      {componentId: "stripPageNumber", name: "Page", width: "5"},
    ],
  },

  eventIndexFooter: {
    height: null,
    flexHeight: "nogrow",
  },

  heartRateSummary: {
    height: null,
    flexHeight: "nogrow",
  },

  stripHeader: {
    height: null,
    flexHeight: "nogrow",
    columns: [
      {align: "start", width: "5", rows: [{componentId: "stripOrder", width: "grow"}]},
      {align: "start", width: "40", rows: [{componentId: "stripUserClassification", width: "none"}]},
      {align: "start", width: "55", rows: [{componentId: "stripTimestamp", width: "none"}]},
    ],
  },

  stripFooter: {
    height: null,
    flexHeight: "nogrow",
    rows: [
      {
        height: "",
        content: [
          {
            width: "none",
            title: "Measurements",
          },
          {
            width: "grow",
            title: "Trigger:",
            rows: [
              {componentId: "triggerType", width: ""},
              {componentId: "triggerDescription", width: ""},
            ],
          },
          {
            width: "grow",
            title: "Event Classification:",
            rows: [
              {componentId: "eventClassification", width: ""},
              {componentId: "beatMarkerCounts", width: ""},
            ],
          },
        ],
      },
      {
        height: "",
        content: [
          {
            width: "grow",
            title: "Findings:",
            rows: [{componentId: "stripComments", width: ""}],
          },
        ],
      },
    ],
  },

  stripSegment: {
    height: 209,
    flexHeight: "nogrow",
  },

  verticalSpacer: {
    height: 0,
    flexHeight: "grow",
  },

  placeholder: {
    height: null,
    flexHeight: "nogrow",
  },

  header: {
    height: 59,
    flexHeight: "nogrow",
    columns: [
      {
        align: "space-around start",
        rows: [
          {type: "text", componentId: "patient", limit: "70"},
          {type: "text", componentId: "patientId", limit: "20"},
          {type: "text", componentId: "studyDeviceSerial"},
        ],
      },
      {
        align: "space-around center",
        rows: [{type: "title"}, {type: "text", componentId: "studyId"}],
      },
      {
        align: "space-around end",
        rows: [{type: "logo"}],
      },
    ],
  },
  summary: {
    height: null,
    flexHeight: "nogrow",
    columns: [
      [
        {
          height: "none",
          title: "Patient Summary",
          rows: [
            [
              {componentId: "patientName", width: "", limit: "70"},
              {componentId: "patientId", width: "50", limit: "20"},
            ],
            [
              {componentId: "patientDob", width: "", limit: "10"},
              {componentId: "patientGender", width: "50", limit: "10"},
            ],
            [
              {componentId: "patientHeight", width: "", limit: "10"},
              {componentId: "patientWeight", width: "50", limit: "10"},
            ],
            [
              {componentId: "patientLanguage", width: "", ellipsis: "2"},
              {componentId: "patientPhone", width: "50", limit: "20"},
            ],
            [{componentId: "patientAddress", width: "", ellipsis: "2"}],
          ],
        },
        {
          height: "grow",
          title: "Ordering Physician Summary",
          rows: [
            [
              {componentId: "physicianName", width: "", limit: "70"},
              {componentId: "physicianPhone", width: "50", limit: "20"},
            ],
            [
              {componentId: "physicianFacility", width: "", ellipsis: "2"},
              {componentId: "physicianEmail", width: "50", ellipsis: "2"},
            ],
            [{componentId: "physicianAddress", width: "", ellipsis: "2"}],
          ],
        },
      ],
      [
        {
          height: "none",
          title: "Report Summary",
          rows: [[{componentId: "eventTimestamp", width: ""}]],
        },
        {
          height: "grow",
          title: "Study Summary",
          rows: [
            [
              {componentId: "studyDeviceSerial", width: ""},
              {componentId: "studyId", width: "50"},
            ],
            [
              {componentId: "studyStartDate", width: ""},
              {componentId: "studyType", width: "50"},
            ],
            [{componentId: "facilityName", width: ""}],
            [{componentId: "studyIndication", width: ""}],
          ],
        },
      ],
    ],
  },
  footer: {
    height: 23,
    flexHeight: "auto",
    items: ["reportCreatedAt", "versionRendered", "reportNumber", "page"],
  },
  technicianFindings: {
    height: null,
    flexHeight: "nogrow",
    items: [
      {componentId: "technicianSignatureNameLabel", width: "none"},
      {componentId: "technicianSignatureName", width: "15"},
      {componentId: "technicianSignatureLabel", width: "none"},
      {componentId: "technicianSignature", width: "35"},
      {componentId: "signatureDateLabel", width: "none"},
      {componentId: "technicianSignatureDate", width: "20"},
    ],
  },
  physicianComments: {
    height: null,
    flexHeight: "grow",
    items: [
      {componentId: "physicianSignatureNameLabel", width: "none"},
      {componentId: "signatureLine", width: "30"},
      {componentId: "physicianSignatureLabel", width: "none"},
      {componentId: "signatureLine", width: "30"},
      {componentId: "signatureDateLabel", width: "none"},
      {componentId: "signatureLine", width: "10"},
    ],
    commentBoxHeight: 150,
  },
  eSignPhysicianComments: {
    height: 208,
    flexHeight: "grow",
    items: [
      {componentId: "physicianSignatureNameLabel", width: "none"},
      {componentId: "physicianSignatureName", width: "15"},
      {componentId: "physicianSignatureLabel", width: "none"},
      {componentId: "physicianSignature", width: "35"},
      {componentId: "signatureDateLabel", width: "none"},
      {componentId: "physicianSignatureDate", width: "20"},
    ],
  },
};

export default defaultReportConfig;
