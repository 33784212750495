/* @ngInject */
export default function routes($stateProvider) {
  $stateProvider.state({
    name: "Completed",
    parent: "App",
    url: "/completed",
    component: "completed",
    onEnter: [
      "Authentication",
      "Session",
      function authenticate(Authentication, Session) {
        Session.trackCurrentPage("/completed");
        return Authentication.authenticate();
      },
    ],
    onExit: [
      "Authentication",
      "InboxItemService",
      function unlockItem(Authentication, InboxItemService) {
        if (Authentication.getJwt()) {
          return InboxItemService.deselectItem();
        }
        return Promise.resolve();
      },
    ],
    data: {
      searchable: true,
      roles: ["tzAdmin", "tech", "facilityAdmin"],
    },
  });
}
