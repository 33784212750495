import pugFile from "./support.pug";

/* @ngInject */
export default function routes($stateProvider) {
  $stateProvider.state({
    name: "Support",
    parent: "App",
    url: "/support",
    template: pugFile(),
    onEnter: [
      "Authentication",
      "Session",
      function authenticate(Authentication, Session) {
        Session.trackCurrentPage("/support");
        return Authentication.authenticate();
      },
    ],
    data: {
      roles: ["clinicalStaff", "tzAdmin", "facilityAdmin", "physician", "tech", "triageTech"],
    },
  });
}
