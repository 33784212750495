import angular from "angular";

import activeStudies from "./activeStudies/index.js";
import completed from "./completed/index.js";
import generatedReport from "./generatedReport/index.js";
import inbox from "./inbox/index.js";
import login from "./login/index.js";
import reports from "./reports/index.js";
import support from "./support/index.js";
import triage from "./triage/index.js";

export default angular.module("app.pages", [
  activeStudies,
  completed,
  inbox,
  login,
  reports,
  support,
  triage,
  generatedReport,
]).name;
