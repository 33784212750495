import angular from "angular";
import uiRouter from "@uirouter/angularjs";

import sharedModule from "../../shared/index.js";
import inboxComponent from "./inbox.component.js";
import InboxController from "./inbox.controller.js";
import routes from "./inbox.routes.js";

export default angular
  .module("app.pages.inbox", [sharedModule, uiRouter])
  .controller("InboxController", InboxController)
  // matches <inbox>
  .component("inbox", inboxComponent)
  .config(routes).name;
