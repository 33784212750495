import angular from "angular";
import uirouter from "@uirouter/angularjs";

import triageComponent from "./triage.component.js";
import TriageController from "./triage.controller.js";
import routes from "./triage.routes.js";

export default angular
  .module("app.pages.triage", [uirouter])
  .config(routes)
  .controller("TriageController", TriageController)
  // matches <triage>
  .component("triage", triageComponent).name;
