/* @ngInject */
export default function routes($stateProvider, $urlRouterProvider) {
  const oldUrl = "/inbox";
  const url = "/analyze";

  $stateProvider.state({
    name: "analyze",
    parent: "App",
    url,
    component: "inbox",
    onEnter: [
      "Authentication",
      "Session",
      function authenticate(Authentication, Session) {
        Session.trackCurrentPage(url);
        return Authentication.authenticate();
      },
    ],
    onExit: [
      "Authentication",
      "InboxItemService",
      function unlockItem(Authentication, InboxItemService) {
        if (Authentication.getJwt()) {
          return InboxItemService.deselectItem();
        }
        return Promise.resolve();
      },
    ],
    data: {
      searchable: true,
      roles: ["tzAdmin", "tech", "facilityAdmin"],
    },
  });

  $urlRouterProvider.when(oldUrl, url);
}
